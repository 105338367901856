var stories = [
  {
    id: 1,
    list_img: require("../assets/story/list/list_Story_01.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_01.jpeg")],
    title: "肥貓的福利：貓肥免費",
    description: "「老闆，來一份特大碗！」夏天，減什麼肥！胖就是正義！",
    fixed: 1
  },
  {
    id: 2,
    list_img: require("../assets/story/list/list_Story_02.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_02.jpeg")],
    title: "Q毛的一天",
    description:
      "有隻有自然捲的貓叫Q毛，有嚴重的潔癖，每天都想把他的自然捲拉直。雖然Q毛是隻貓，但他也養了貓跟很多小寵物。",
    fixed: 1
  },
  {
    id: 3,
    list_img: require("../assets/story/list/list_Story_03.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_03.jpeg")],
    title: "Q毛逛市集",
    description: "有潔癖的Q毛穿著鞋子怕弄髒肉球，趁著天氣好去市集採購～",
    fixed: 1
  },
  {
    id: 4,
    list_img: require("../assets/story/list/list_Story_04.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_04.jpeg")],
    title: "Q毛的午睡時間",
    description:
      "自然捲好像又捲回來了，奇怪！怎麼在夢中也覺得自己在狂拉直自然捲呢？",
    fixed: 1
  },
  {
    id: 5,
    list_img: require("../assets/story/list/list_Story_05.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_05.jpeg")],
    title: "Q毛下午茶",
    description: "惱人的自然捲就先暫時放一邊吧，來杯熱熱暖暖的拿鐵。",
    fixed: 1
  },
  {
    id: 6,
    list_img: require("../assets/story/list/list_Story_06.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_06.jpeg")],
    title: "Q毛的萬聖節",
    description: "Trick or Treat!!交出你的糖果啦！",
    fixed: 1
  },
  {
    id: 7,
    list_img: require("../assets/story/list/list_Story_07.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_07.jpeg")],
    title: "Q毛的下雨天1",
    description: "下雨天毛躁的自然捲又更捲了，下著雨不能出去玩，心情好煩悶啊～",
    fixed: 1
  },
  {
    id: 8,
    list_img: require("../assets/story/list/list_Story_08.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_08.jpeg")],
    title: "Q毛的下雨天2",
    description:
      "啊！想起上次慶生的氦氣桶還沒用玩，我們來玩氦氣吧！飛呀飛呀飛高高，吸阿吸阿圓滾滾～",
    fixed: 1
  },
  {
    id: 9,
    list_img: require("../assets/story/list/list_Story_09.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_09.jpeg")],
    title: "Q毛的下雨天3",
    description: "糟糕了…肚子好餓啊！！不知道要幾天後才能回到地面……",
    fixed: 1
  },
  {
    id: 10,
    list_img: require("../assets/story/list/list_Story_10.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_10.jpeg")],
    title: "划向貓之島",
    description: "翻開書本，突然前面出現了一座巨大的貓之島！",
    fixed: 1
  },
  {
    id: 11,
    list_img: require("../assets/story/list/list_Story_11.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_11.jpeg")],
    title: "一起來看螢火蟲",
    description: "等啊等的，螢火蟲什麼時候要出來呢？",
    fixed: 0
  },
  {
    id: 12,
    list_img: require("../assets/story/list/list_Story_12.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_12.jpeg")],
    title: "出發去冒險吧！",
    description:
      "在11點前上床睡覺進入夢鄉的孩子們，才有機會拿到冒險列車的車票喔！",
    fixed: 0
  },
  {
    id: 13,
    list_img: require("../assets/story/list/list_Story_13.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_13.jpeg")],
    title: "早安森林樂團",
    description: "是不是該起床拉？來點早安樂曲～美好的一天又開始啦！",
    fixed: 0
  },
  {
    id: 14,
    list_img: require("../assets/story/list/list_Story_14.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_14.jpeg")],
    title: "抓住夏天的尾巴",
    description: "夏天等等，別走啊！",
    fixed: 0
  },
  {
    id: 15,
    list_img: require("../assets/story/list/list_Story_15.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_15.jpeg")],
    title: "森林裡的新朋友",
    description: "是上帝派來的禮物嗎？",
    fixed: 0
  },
  {
    id: 16,
    list_img: require("../assets/story/list/list_Story_16.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_16.jpeg")],
    title: "迷路的巧遇",
    description: "那些顧著摘花而迷路的小狐狸們，找到回家的路了嗎？",
    fixed: 0
  },
  {
    id: 17,
    list_img: require("../assets/story/list/list_Story_17.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_17.jpeg")],
    title: "父親節快樂",
    description: "喜歡抱抱了！爸爸就像一個大樹一樣～",
    fixed: 0
  },
  {
    id: 18,
    list_img: require("../assets/story/list/list_Story_18.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_18.jpeg")],
    title: "貓體盛",
    description: "來來來～店家招待！",
    fixed: 0
  },
  {
    id: 19,
    list_img: require("../assets/story/list/list_Story_19.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_19.jpeg")],
    title: "夢中圓舞曲",
    description: "交織的夢境一起舞蹈吧。",
    fixed: 0
  },
  {
    id: 20,
    list_img: require("../assets/story/list/list_Story_20.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_20.jpeg")],
    title: "早餐時光",
    description: "來份報紙吧",
    fixed: 0
  },
  {
    id: 21,
    list_img: require("../assets/story/list/list_Story_21.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_21.jpeg")],
    title: "有時候只想靜靜呆著",
    description: "在一個沒人注意安靜的小角落…發著呆",
    fixed: 0
  },
  {
    id: 22,
    list_img: require("../assets/story/list/list_Story_22.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_22.jpeg")],
    title: "新春快樂祈福",
    description: "新年快樂！",
    fixed: 0
  },
  {
    id: 23,
    list_img: require("../assets/story/list/list_Story_23.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_23.jpeg")],
    title: "睡不著的罪魁禍首",
    description: "我…一定要消滅你！就別讓我逮到你！",
    fixed: 0
  },
  {
    id: 24,
    list_img: require("../assets/story/list/list_Story_24.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_24.jpeg")],
    title: "貓肥人富",
    description: "鍵上的貓頭四賀新年",
    fixed: 0
  },
  {
    id: 25,
    list_img: require("../assets/story/list/list_Story_25.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_25.jpeg")],
    title: "福氣貓",
    description: "",
    fixed: 0
  },
  {
    id: 26,
    list_img: require("../assets/story/list/list_Story_26.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_26.jpeg")],
    title: "飛天豬的旅行",
    description: "",
    fixed: 0
  },
  {
    id: 27,
    list_img: require("../assets/story/list/list_Story_27.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_27.jpeg")],
    title: "旋轉、跳躍、我閉著眼",
    description: "",
    fixed: 0
  },
  {
    id: 28,
    list_img: require("../assets/story/list/list_Story_28.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_28.jpeg")],
    title: "豬年快樂發豬財",
    description: "吉豬吉利",
    fixed: 0
  },
  {
    id: 29,
    list_img: require("../assets/story/list/list_Story_29.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_29.jpeg")],
    title: "新年快樂",
    description: "",
    fixed: 0
  },
  {
    id: 30,
    list_img: require("../assets/story/list/list_Story_30.jpeg"),
    orig_img: [require("../assets/story/item/item_Story_30.jpeg")],
    title: "新年快樂",
    description: "",
    fixed: 0
  }
];

export default stories;
