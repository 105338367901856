<template>
  <div class="about-page main">
    <div class="ss-container">
      <div class="d-flex flex-column flex-md-row">
        <div class="avatar">
          <img :src="require('../assets/avatar.png')" alt="Chihyi CC 林芷伊" />
        </div>
        <div class="author">
          <h1>Chihyi CC<span style="margin-left: 8px;">林芷伊</span></h1>
          <p>
            誤打誤撞因養貓愛貓畫貓，進入插畫腦補世界。
          </p>
          <p>
            Entered the illustration work because of raising cats and loving
            cats to draw cats.
          </p>
          <div class="signature">
            <img
              :src="require('../assets/signature.png')"
              alt="Chihyi CC 林芷伊 簽名"
            />
          </div>
        </div>
      </div>
      <div class="hr"></div>
      <div class="service">
        <h2>服務項目</h2>
        <div class="d-flex flex-column flex-md-row">
          <div class="service-1">
            <p>插畫 Illustration</p>
            <p>似顏繪 Caricature Portrait</p>
            <p>繪本 Picture Book</p>
          </div>
          <div class="service-2">
            <p>視覺設計 Visual Design</p>
            <p>書籍封面設計 Book Cover Design</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {}
};
</script>

<style lang="sass" scoped>
.about-page
  padding-top: 95px
  +deviceWidth()
    padding-top: 50px
.avatar
  width: 120px
  position: relative
  left: -6px
  img
    max-width: 100%
    height: auto
.author
  width: 100%
  max-width: 590px
  +deviceWidth()
    padding-top: 20px
  +deviceWidth(768,0)
    margin-left: 66px
  h1
    font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
    font-size: 15px
    font-weight: 500
    color: #111
    letter-spacing: .38px
    margin-bottom: 20px
  p
    font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
    font-size: 13px
    color: #111
    letter-spacing: 1.08px
    margin-bottom: 0
    line-height: 1.8
    opacity: .5
  .signature
    width: 80px
    margin-top: 32px
    opacity: .5
.hr
  width: 101.25%
  height: 1px
  background-color: #c5c5c5
  margin-top: 115px
  margin-bottom: 65px
  position: relative
  left: -.625%
  opacity: 1
  +deviceWidth()
    margin-top: 56px
    margin-bottom: 30px
.service
  padding-bottom: 140px
  +deviceWidth()
    padding-bottom: 70px
  .service-2
    +deviceWidth(768,0)
      margin-left: 96px
  h2
    font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
    font-size: 15px
    font-weight: 500
    color: #111
    letter-spacing: .38px
    margin-bottom: 24px
  p
    font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
    font-size: 12px
    color: #111
    letter-spacing: 1px
    margin-bottom: 0
    line-height: 2
    opacity: .5
    padding-bottom: 12px
</style>
