var portraits = [
  {
    id: 1,
    list_img: require("../assets/portrait/list_Portrait_01.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_01.jpeg")],
    title: "貓化似顏繪",
    description: "客訂似顏繪",
    fixed: 1
  },
  {
    id: 2,
    list_img: require("../assets/portrait/list_Portrait_02.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_02.jpeg")],
    title: "貓化似顏繪",
    description: "客訂似顏繪",
    fixed: 1
  },
  {
    id: 3,
    list_img: require("../assets/portrait/list_Portrait_03.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_03.jpeg")],
    title: "貓化似顏繪",
    description: "客訂似顏繪",
    fixed: 1
  },
  {
    id: 4,
    list_img: require("../assets/portrait/list_Portrait_04.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_04.jpeg")],
    title: "貓化似顏繪",
    description: "客訂似顏繪",
    fixed: 1
  },
  {
    id: 5,
    list_img: require("../assets/portrait/list_Portrait_05.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_05.jpeg")],
    title: "貓化似顏繪（寵物與主人）",
    description: "客訂似顏繪",
    fixed: 1
  },
  {
    id: 6,
    list_img: require("../assets/portrait/list_Portrait_06.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_06.jpeg")],
    title: "貓化似顏繪（寵物與主人）",
    description: "客訂似顏繪",
    fixed: 0
  },
  {
    id: 7,
    list_img: require("../assets/portrait/list_Portrait_07.jpeg"),
    orig_img: [require("../assets/portrait/item_Portrait_07.jpeg")],
    title: "婚禮小物插畫",
    description: "客訂似顏繪",
    fixed: 0
  }
];

export default portraits;
