var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:_vm.burgerMenuOpen ? 'active' : ''},[_c('div',{staticClass:"ss-container"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('h5',{class:_vm.theme == 1 ? 'text-white' : 'text-black'},[_vm._v(" CHIHYI ")])]),_c('nav',{staticClass:"menubar"},[_c('ul',{staticClass:"menubar-ul"},[_c('li',{staticClass:"z-index-15"},[_c('button',{class:_vm.theme == 1 ? 'text-white' : 'text-black',attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleDropdownMenu()}}},[_vm._v(" Projects ")]),_c('div',{staticClass:"dropdown",class:[
              _vm.dropdownMenuOpen ? 'active' : '',
              _vm.theme == 1 ? 'text-white' : 'text-black'
            ]},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/illustration"}},[_vm._v("Illustration "),_c('span',[_vm._v("一些小插畫")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/stories"}},[_vm._v(" Story "),_c('span',[_vm._v("那些小故事")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/characters"}},[_vm._v("Character "),_c('span',[_vm._v("這些小角色")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/portraits"}},[_vm._v("Caricature Portrait "),_c('span',[_vm._v("客訂似顏繪")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/commercial-illustration"}},[_vm._v("Commercial Illustration "),_c('span',[_vm._v("商業插畫設計")])])],1),_c('li',[_c('router-link',{attrs:{"to":"/book-cover-design"}},[_vm._v("Book Cover Design "),_c('span',[_vm._v("書籍封面設計")])])],1)]),_c('button',{staticClass:"back",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeDropdownMenu()}}},[_c('img',{attrs:{"src":_vm.theme == 1
                    ? require('../assets/icon_back_wh.svg')
                    : require('../assets/icon_back_bk.svg'),"alt":"箭頭"}})]),_c('button',{staticClass:"hamburger hamburger--slider is-active",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeBothMenus()}}},[_c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner",class:_vm.theme == 1 ? 'bg-white' : 'bg-black'})])])])]),_c('li',{},[_c('router-link',{class:_vm.theme == 1 ? 'text-white' : 'text-black',attrs:{"to":"/about"}},[_vm._v("About")])],1),_c('li',{},[_c('router-link',{class:_vm.theme == 1 ? 'text-white' : 'text-black',attrs:{"to":"/contact"}},[_vm._v("Contact")])],1),_vm._m(0),_c('li',{staticClass:"instagram"},[_c('a',{attrs:{"href":"https://www.instagram.com/chihyicc/","target":"_blank"}},[_c('img',{attrs:{"src":_vm.theme == 1
                  ? require('../assets/ig_logo_wh.svg')
                  : require('../assets/ig_logo_bk.svg'),"alt":"instagram"}})])])])]),_c('button',{staticClass:"hamburger hamburger--slider",class:_vm.burgerMenuOpen ? 'is-active' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleBurgerMenu()}}},[_c('span',{staticClass:"hamburger-box"},[_c('span',{staticClass:"hamburger-inner",class:_vm.theme == 1 ? 'bg-white' : 'bg-black'})])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"d-md-none"},[_c('div',{staticClass:"hr"})])}]

export { render, staticRenderFns }