<template>
  <div class="ss-container">
    <div class="ss-row row">
      <div
        v-for="(item, index) in data"
        :class="
          category === 'character' && item.size === 2
            ? 'col-12'
            : 'col-6 col-md-3 ss-col-2'
        "
        :key="index + 1"
      >
        <router-link
          :to="'/' + category + '/' + item.id"
          class="br-18 overflow-hidden"
          :class="
            category === 'character' && item.size === 2 ? 'item-fluid' : 'item'
          "
        >
          <img :src="item.list_img" :alt="item.title" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListTemplate",
  props: {
    data: Array,
    category: String
  }
};
</script>

<style scoped lang="sass">
.br-18
  border-radius: 18px
.overflow-hidden
  overflow: hidden
.item
  max-width: 240px
  width: 100%
  margin-bottom: 92px
  display: block
  &:focus
    outline: none
  +deviceWidth()
    margin-bottom: 30px
  img
    max-width: 100%
    height: auto
.item-fluid
  width: 100%
  margin-bottom: 92px
  display: block
  &:focus
    outline: none
  +deviceWidth()
    margin-bottom: 30px
  img
    max-width: 100%
    height: auto
</style>
