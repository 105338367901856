var bookCoverDesign = [
  {
    id: 1,
    list_img: require("../assets/book_cover/item_bookCover_01.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_01.jpeg")],
    title: "《每一個否定，帶你更接近肯定》",
    category: "書籍封面插畫／書籍封面設計",
    client: "時報出版",
    fixed: 1
  },
  {
    id: 2,
    list_img: require("../assets/book_cover/item_bookCover_02.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_02.jpeg")],
    title: "《水的導讀》",
    category: "書籍封面插畫／書籍封面設計",
    client: "行人出版",
    fixed: 1
  },
  {
    id: 3,
    list_img: require("../assets/book_cover/item_bookCover_03.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_03.jpeg")],
    title: "《摺紙幾何學》",
    category: "書籍封面插畫／書籍封面設計",
    client: "世茂出版",
    fixed: 1
  },
  {
    id: 4,
    list_img: require("../assets/book_cover/item_bookCover_04.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_04.jpeg")],
    title: "《製本仕事人》",
    category: "書籍封面插畫／書籍封面設計",
    client: "世茂出版",
    fixed: 1
  },
  {
    id: 5,
    list_img: require("../assets/book_cover/item_bookCover_05.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_05.jpeg")],
    title: "《減法健康》",
    category: "書籍封面設計",
    client: "時報出版",
    fixed: 1
  },
  {
    id: 6,
    list_img: require("../assets/book_cover/item_bookCover_06.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_06.jpeg")],
    title: "《喚醒青春的賀爾蒙》",
    category: "書籍封面設計",
    client: "世茂出版",
    fixed: 0
  },
  {
    id: 7,
    list_img: require("../assets/book_cover/item_bookCover_07.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_07.jpeg")],
    title: "《提案必看的簡報小抄》",
    category: "書籍封面設計",
    client: "商業週刊出版",
    fixed: 0
  },
  {
    id: 8,
    list_img: require("../assets/book_cover/item_bookCover_08.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_08.jpeg")],
    title: "《不放手的婚姻》",
    category: "書籍封面設計",
    client: "時報出版",
    fixed: 0
  },
  {
    id: 9,
    list_img: require("../assets/book_cover/item_bookCover_09.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_09.jpeg")],
    title: "《改變世界的碳元素》",
    category: "書籍封面插畫／書籍封面設計",
    client: "世茂出版",
    fixed: 0
  },
  {
    id: 10,
    list_img: require("../assets/book_cover/item_bookCover_10.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_10.jpeg")],
    title: "《宅在家，多自在》",
    category: "書籍封面設計",
    client: "時報出版",
    fixed: 0
  },
  {
    id: 11,
    list_img: require("../assets/book_cover/item_bookCover_11.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_11.jpeg")],
    title: "《全圖解步驟教學你的第一本Python入門課》",
    category: "書籍封面設計",
    client: "商業週刊出版",
    fixed: 0
  },
  {
    id: 12,
    list_img: require("../assets/book_cover/item_bookCover_12.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_12.jpeg")],
    title: "《風味達人的文字味覺》",
    category: "書籍封面設計",
    client: "商周出版",
    fixed: 0
  },
  {
    id: 13,
    list_img: require("../assets/book_cover/item_bookCover_13.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_13.jpeg")],
    title: "《顧喉嚨：喉嚨好，免疫力就好》",
    category: "書籍封面插畫／書籍封面設計",
    client: "世茂出版",
    fixed: 0
  },
  {
    id: 14,
    list_img: require("../assets/book_cover/item_bookCover_14.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_14.jpeg")],
    title: "《詐騙心理學》",
    category: "書籍封面插畫／書籍封面設計",
    client: "世茂出版",
    fixed: 0
  },
  {
    id: 15,
    list_img: require("../assets/book_cover/item_bookCover_15.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_15.jpeg")],
    title: "《讀書的價值》",
    category: "書籍封面插畫／書籍封面設計",
    client: "世茂出版",
    fixed: 0
  },
  {
    id: 16,
    list_img: require("../assets/book_cover/item_bookCover_16.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_16.jpeg")],
    title: "《你的溫柔要有底線》",
    category: "書籍封面設計",
    client: "世茂出版",
    fixed: 0
  },
  {
    id: 17,
    list_img: require("../assets/book_cover/item_bookCover_17.jpeg"),
    orig_img: [require("../assets/book_cover/item_bookCover_17.jpeg")],
    title: "《30秒專注力法則》",
    category: "書籍封面設計",
    client: "商業週刊出版",
    fixed: 0
  }
];

export default bookCoverDesign;
