import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Contact from "../views/Contact.vue";
import Error404 from "../views/Error404.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact
  },
  {
    path: "/illustration/:item_id",
    name: "IllustrationItem",
    component: () => import("../views/IllustrationItem.vue")
  },
  {
    path: "/illustration",
    name: "Illustration",
    component: () => import("../views/Illustration.vue")
  },
  {
    path: "/story/:item_id",
    name: "StoryItem",
    component: () => import("../views/StoryItem.vue")
  },
  {
    path: "/stories",
    name: "Story",
    component: () => import("../views/Story.vue")
  },
  {
    path: "/character/:item_id",
    name: "CharacterItem",
    component: () => import("../views/CharacterItem.vue")
  },
  {
    path: "/characters",
    name: "Character",
    component: () => import("../views/Character.vue")
  },
  {
    path: "/portrait/:item_id",
    name: "PortraitItem",
    component: () => import("../views/PortraitItem.vue")
  },
  {
    path: "/portraits",
    name: "Portrait",
    component: () => import("../views/Portrait.vue")
  },
  {
    path: "/commercial-illustration/:item_id",
    name: "CommercialIllustrationItem",
    component: () => import("../views/CommercialIllustrationItem.vue")
  },
  {
    path: "/commercial-illustration",
    name: "CommercialIllustration",
    component: () => import("../views/CommercialIllustration.vue")
  },
  {
    path: "/book-cover-design/:item_id",
    name: "BookCoverDesignItem",
    component: () => import("../views/BookCoverDesignItem.vue")
  },
  {
    path: "/book-cover-design",
    name: "BookCoverDesign",
    component: () => import("../views/BookCoverDesign.vue")
  },
  {
    path: "/error",
    name: "Error404",
    component: Error404
  },
  {
    path: "*",
    component: Error404
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
