var commercialIllustrations = [
  {
    id: 1,
    list_img: require("../assets/comm_illustration/list_CommIllustration_01.jpeg"),
    orig_img: [
      require("../assets/comm_illustration/item_CommIllustration_01a.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_01b.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_01c.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_01d.jpeg")
    ],
    title: "信義親子館「森林我的家」",
    titleEN: "Forest, my home.",
    category: "Illustration & Vision design",
    client: "信義親子館（Date： 2018 Oct）",
    link:
      "https://www.behance.net/gallery/71836907/-XinYi-Parent-Child-Play-Center",
    fixed: 1
  },
  {
    id: 2,
    list_img: require("../assets/comm_illustration/list_CommIllustration_02.jpeg"),
    orig_img: [
      require("../assets/comm_illustration/item_CommIllustration_02a.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_02b.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_02c.jpeg")
    ],
    title: "信義親子館「歡樂慶豐年」",
    titleEN: "Happy Harvest Festival",
    category: "Illustration & Vision design",
    client: "信義親子館（Date： 2019 Feb）",
    link: "https://www.behance.net/gallery/85643709/-Happy-Harvest-Festival",
    fixed: 1
  },
  {
    id: 3,
    list_img: require("../assets/comm_illustration/list_CommIllustration_03.jpeg"),
    orig_img: [
      require("../assets/comm_illustration/item_CommIllustration_03a.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_03b.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_03c.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_03d.jpeg")
    ],
    title: "托育資源中心（親子館）工作操作手冊",
    titleEN: "",
    category: "Illustration & Book design",
    client: "兒童福利聯盟文教基金會<br>（Date：2019  Jan）",
    link: "https://www.behance.net/gallery/85643709/-Happy-Harvest-Festival",
    fixed: 1
  },
  {
    id: 4,
    list_img: require("../assets/comm_illustration/list_CommIllustration_04.jpeg"),
    orig_img: [
      require("../assets/comm_illustration/item_CommIllustration_04a.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_04b.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_04c.jpeg")
    ],
    title: "新北好客都 Vol.33",
    titleEN: "Travel Map Illustration",
    description: "客遊新北名人帶路 專欄",
    category: "地圖插畫<br>Travel map illustration",
    client: "新北市政府客家事務局<br>（Date：2016  June）",
    link:
      "https://www.behance.net/gallery/55492139/-Vol33Travel-Map-Illustration",
    fixed: 1
  },
  {
    id: 5,
    list_img: require("../assets/comm_illustration/list_CommIllustration_05.jpeg"),
    orig_img: [
      require("../assets/comm_illustration/item_CommIllustration_05a.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_05b.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_05c.jpeg")
    ],
    title: "翰林社會課本封面插畫",
    titleEN: "",
    category: "Cover illustration",
    client: "翰林出版（Date：2019  Sep）",
    link:
      "https://www.behance.net/gallery/85584125/Textbook-cover-illustration",
    fixed: 1
  },
  {
    id: 6,
    list_img: require("../assets/comm_illustration/list_CommIllustration_06.jpeg"),
    orig_img: [
      require("../assets/comm_illustration/item_CommIllustration_06a.jpeg"),
      require("../assets/comm_illustration/item_CommIllustration_06b.jpeg")
    ],
    title: "新北好客都 Vol.34",
    titleEN: "Travel Map Illustration",
    description: "北海岸線的自由流浪",
    category: "地圖插畫<br>Travel map illustration",
    client: "新北市政府客家事務局<br>（Date：2017  Feb）",
    link:
      "https://www.behance.net/gallery/63934267/-Vol34Travel-Map-Illustration",
    fixed: 0
  }
];

export default commercialIllustrations;
