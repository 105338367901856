// type 1: 圖主要是白色，配黑底, 2: 圖主要是黑色，配白底
// size 1: 圖短的，橫的 2: 圖長的，直的
var illustrations = [
  {
    id: 1,
    list_img: require("../assets/illustration/s1_t1_01.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_01.jpeg")],
    title: "2020再見倒數",
    description: "2021希望大家都健康平安",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 2,
    list_img: require("../assets/illustration/s1_t1_02.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_02.jpeg")],
    title: "紅蘿蔔與小黃瓜對決之不可以挑食",
    description: "「吃我的蘿蔔手裡雞」「看我的黃瓜飛刀」",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 3,
    list_img: require("../assets/illustration/s1_t1_03.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_03.jpeg")],
    title: "Cat Emperor I貓皇一世",
    description:
      "嗨，還記得貓皇二世嗎？他老爸貓皇一世是超級鹹魚愛好者，愛吃到腳會散發出濃厚的鹹魚味。為爭奪鹹魚開啓貿易戰爭，戰無不勝征服了整個南貓洲。傳聞中聞貓皇一世雕像的腳若聞到鹹魚味所祈求的願望都能成真！大批觀光客慕名而來朝聖，因曾經遷都過，舊址已經被土著貓佔領，想聞貓皇一世的腳必須向土著貓納貢，已成為土著貓部落的主要收入來源。",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 4,
    list_img: require("../assets/illustration/s1_t1_04.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_04.jpeg")],
    title: "香菇島",
    description: "雨下啊下的淹成一片汪洋，我造了一座小島卻長滿香菇。",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 5,
    list_img: require("../assets/illustration/s1_t1_05.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_05.jpeg")],
    title: "Radio小精靈",
    description: "播放時小精靈正在看不到的角落玩耍呢！",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 6,
    list_img: require("../assets/illustration/s1_t1_06.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_06.jpeg")],
    title: "Día de los Muertos",
    description: "貓咪亡靈節，喵喵夜總會。",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 7,
    list_img: require("../assets/illustration/s1_t1_07.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_07.jpeg")],
    title: "避雷針的用法？",
    description: "真的…是這樣用嗎？",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 8,
    list_img: require("../assets/illustration/s1_t1_08.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_08.jpeg")],
    title: "好朋友時光",
    description: "難過時有我陪著你。",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 9,
    list_img: require("../assets/illustration/s1_t1_09.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_09.jpeg")],
    title: "呵欠小精靈",
    description: "你知道打呵欠是會傳染的嗎？",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 10,
    list_img: require("../assets/illustration/s1_t1_10.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_10.jpeg")],
    title: "想看星星時…",
    description: "就自己造一片星空。",
    size: 1,
    type: 1,
    fixed: 1
  },
  {
    id: 11,
    list_img: require("../assets/illustration/s1_t1_11.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_11.jpeg")],
    title: "貓雞對決",
    description: "喵雞大戰",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 12,
    list_img: require("../assets/illustration/s1_t1_12.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_12.jpeg")],
    title: "炒飯衝浪",
    description: "看看大廚這純熟的翻鍋技巧。",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 13,
    list_img: require("../assets/illustration/s1_t1_13.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_13.jpeg")],
    title: "Hide",
    description: "想被了解，快要被看透時，又會想找個地方藏起來。",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 14,
    list_img: require("../assets/illustration/s1_t1_14.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_14.jpeg")],
    title: "日式炒麵之戀",
    description: "麵條的另一端原來是愛情！",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 15,
    list_img: require("../assets/illustration/s1_t1_15.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_15.jpeg")],
    title: "Merry Xmas",
    description: "別再貪睡偷懶了！快趕不上平安夜了～",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 16,
    list_img: require("../assets/illustration/s1_t1_16.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_16.jpeg")],
    title: "外太空火箭扭蛋",
    description: "「喔喔喔～我轉出了一枚超人小雞～」",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 17,
    list_img: require("../assets/illustration/s1_t1_17.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_17.jpeg")],
    title: "睡不著時",
    description: "就數數羊吧",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 18,
    list_img: require("../assets/illustration/s1_t1_18.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_18.jpeg")],
    title: "深夜裡的廚房",
    description: "蠢蠢欲動的小房客們。",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 19,
    list_img: require("../assets/illustration/s1_t1_19.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_19.jpeg")],
    title: "心情小塗鴉",
    description: "Housework hero / 滿分十分",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 20,
    list_img: require("../assets/illustration/s1_t1_20.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_20.jpeg")],
    title: "鬼門開啦",
    description: "大鬼小鬼喵喵鬼都出來透透氣。",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 21,
    list_img: require("../assets/illustration/s1_t1_21.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_21.jpeg")],
    title: "生日快樂",
    description: "特製驚喜蛋糕快準備好啦！",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 22,
    list_img: require("../assets/illustration/s1_t1_22.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_22.jpeg")],
    title: "散散步",
    description: "溜溜小寵物",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 23,
    list_img: require("../assets/illustration/s1_t1_23.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_23.jpeg")],
    title: "有時候笑並不是真的在笑",
    description: "",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 24,
    list_img: require("../assets/illustration/s1_t1_24.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_24.jpeg")],
    title: "立體拉花",
    description: "",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 25,
    list_img: require("../assets/illustration/s1_t1_25.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_25.jpeg")],
    title: "喵星人",
    description: "",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 26,
    list_img: require("../assets/illustration/s1_t1_26.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_26.jpeg")],
    title: "風景小塗鴉",
    description: "",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 27,
    list_img: require("../assets/illustration/s1_t1_27.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_27.jpeg")],
    title: "來去都兜風吧",
    description: "走囉，出發囉！",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 28,
    list_img: require("../assets/illustration/s1_t1_28.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_28.jpeg")],
    title: "急凍貓／在花園溜哈士奇",
    description: "",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 29,
    list_img: require("../assets/illustration/s1_t1_29.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_29.jpeg")],
    title: "用愛灌愛",
    description: "什麼樣的養分，什麼樣的收穫。",
    size: 1,
    type: 1,
    fixed: 0
  },
  {
    id: 30,
    list_img: require("../assets/illustration/s1_t1_30.jpeg"),
    orig_img: [require("../assets/illustration/s1_t1_30.jpeg")],
    title: "人生",
    description: "還沒結束前都還沒完結篇。",
    size: 1,
    type: 1,
    fixed: 0
  },
  // {
  //   id: 31,
  //   list_img: require("../assets/illustration/s1_t1_31.jpeg"),
  //   orig_img: [require("../assets/illustration/s1_t1_31.jpeg")],
  //   title: "Swing Dance",
  //   description: "跟著音樂節奏一起搖擺。",
  //   size: 1,
  //   type: 1,
  //   fixed: 0
  // },
  // {
  //   id: 32,
  //   list_img: require("../assets/illustration/s1_t1_32.jpeg"),
  //   orig_img: [require("../assets/illustration/s1_t1_32.jpeg")],
  //   title: "動物小塗鴉",
  //   description: "",
  //   size: 1,
  //   type: 1,
  //   fixed: 0
  // },
  // {
  //   id: 33,
  //   list_img: require("../assets/illustration/s1_t1_33.jpeg"),
  //   orig_img: [require("../assets/illustration/s1_t1_33.jpeg")],
  //   title: "色鉛筆小塗鴉",
  //   description: "",
  //   size: 1,
  //   type: 1,
  //   fixed: 0
  // },
  {
    id: 31,
    list_img: require("../assets/illustration/s2_t1_01.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_01.jpeg")],
    title: "Cat Emperor II 貓皇二世",
    description:
      "傳說只要摸到貓皇二世雕像的蛋蛋可以富貴半輩子，許多觀光客都慕名而來朝聖。貓蛋蛋被摸得金光閃閃。",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 32,
    list_img: require("../assets/illustration/s2_t1_02.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_02.jpeg")],
    title: "Cat Emperor II 貓皇二世紀念品館",
    description:
      "參觀完貓皇二世可以去旁邊選購紀念品，這邊有天價的純金蛋珠寶項鍊，還有神秘的金蛋蛋種子，據說會依照播種的人及照顧方式長出不同樣貌的金蛋樹，還有結合超現代科技的金蛋蛋打掃機器人（附加抓蟑螂、老鼠、招財功能）是熱賣商品。也有低價位好入手的金蛋蛋掛飾....等。偷偷告訴你們一個秘密，店員頭上濃密的髮量是去膜拜貓皇一世的腳掌聞到了鹹魚味，讓希望稀疏髮量可以恢復英姿的願望成真。（附註：購買魚劍時，記得評估自己的行李箱大小。）",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 33,
    list_img: require("../assets/illustration/s2_t1_03.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_03.jpeg")],
    title: "海底貓世界",
    description: "「來來來，不可以隨意碰觸這些海底生物喔！」",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 34,
    list_img: require("../assets/illustration/s2_t1_04.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_04.jpeg")],
    title: "征服龍之山",
    description: "讓我來打倒你吧！",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 35,
    list_img: require("../assets/illustration/s2_t1_05.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_05.jpeg")],
    title: "綁鞋帶之必要",
    description: "綁好牢固的鞋帶，是需要練習的。",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 36,
    list_img: require("../assets/illustration/s2_t1_06.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_06.jpeg")],
    title: "喵咒呀～喵咒～",
    description: "實習喵巫正在調配什麼樣的藥水呢？",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 37,
    list_img: require("../assets/illustration/s2_t1_07.jpeg"),
    orig_img: [require("../assets/illustration/s2_t1_07.jpeg")],
    title: "我也想變成貓",
    description:
      "「我也想加入！！要怎麼樣才能栽種出可食用貓化果實？」「喵嗚...讓我看看，兩根公貓鬍鬚、三瓶鹹魚肉球氣味......」",
    size: 2,
    type: 1,
    fixed: 0
  },
  {
    id: 38,
    list_img: require("../assets/illustration/s1_t2_01.jpeg"),
    orig_img: [require("../assets/illustration/s1_t2_01.jpeg")],
    title: "殭屍貓國度",
    description: "「啊啊～我⋯⋯好像走錯地方了⋯⋯」",
    size: 1,
    type: 2,
    fixed: 0
  },
  {
    id: 39,
    list_img: require("../assets/illustration/s1_t2_02.jpeg"),
    orig_img: [require("../assets/illustration/s1_t2_02.jpeg")],
    title: "跟紅鶴一起玩",
    description: "我也要優雅的姿態",
    size: 1,
    type: 2,
    fixed: 0
  },
  {
    id: 40,
    list_img: require("../assets/illustration/s1_t2_03.jpeg"),
    orig_img: [require("../assets/illustration/s1_t2_03.jpeg")],
    title: "跟大象一起玩",
    description: "我也有會噴水的長鼻子",
    size: 1,
    type: 2,
    fixed: 0
  },
  {
    id: 41,
    list_img: require("../assets/illustration/s1_t2_04.jpeg"),
    orig_img: [require("../assets/illustration/s1_t2_04.jpeg")],
    title: "雪國列車",
    description: "即將準備進入貓之雪國，請勿將頭、身體伸出車廂外。",
    size: 1,
    type: 2,
    fixed: 0
  },
  {
    id: 42,
    list_img: require("../assets/illustration/s1_t2_05.jpeg"),
    orig_img: [require("../assets/illustration/s1_t2_05.jpeg")],
    title: "寄封信給在遠方的喵之友",
    description: "喵嗚喵喵喵…",
    size: 1,
    type: 2,
    fixed: 0
  },
  {
    id: 43,
    list_img: require("../assets/illustration/s2_t2_01.jpeg"),
    orig_img: [require("../assets/illustration/s2_t2_01.jpeg")],
    title: "中秋節蛋黃酥跟柚子是必須的",
    description: "Happy Moon Festival! Yolk pastry is the best!",
    size: 2,
    type: 2,
    fixed: 0
  },
  {
    id: 44,
    list_img: require("../assets/illustration/s2_t2_02.jpeg"),
    orig_img: [require("../assets/illustration/s2_t2_02.jpeg")],
    title: "2019/10/13 God bless Japan",
    description:
      "Typhoon Hagibis hits Japan. Hope that casualties can be minimized.",
    size: 2,
    type: 2,
    fixed: 0
  }
];

export default illustrations;
