<template>
  <div id="app" :class="'theme' + theme">
    <Header :theme="theme"></Header>
    <transition name="fade">
      <router-view :key="$route.name" />
    </transition>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      theme: 1
    };
  },
  watch: {
    $route: function(to) {
      if (
        to.name === "CommercialIllustration" ||
        to.name === "CommercialIllustrationItem" ||
        to.name === "BookCoverDesign" ||
        to.name === "BookCoverDesignItem"
      ) {
        this.theme = 2;
      } else if (to.name === "About" || to.name === "Contact") {
        this.theme = 3;
      } else {
        this.theme = 1;
      }
    }
  },
  mounted() {
    if (
      this.$route.name === "CommercialIllustration" ||
      this.$route.name === "CommercialIllustrationItem" ||
      this.$route.name === "BookCoverDesign" ||
      this.$route.name === "BookCoverDesignItem"
    ) {
      this.theme = 2;
    } else if (this.$route.name === "About" || this.$route.name === "Contact") {
      this.theme = 3;
    } else {
      this.theme = 1;
    }
  }
};
</script>

<style lang="sass">
body
  margin: 0
#app
  font-family: 'PingFang TC', "Microsoft JhengHei", Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  +deviceWidth()
    padding-top: 64px
  .ss-container
    width: 100%
    margin: auto
    +deviceWidth(0,1199)
      padding: 0 20px
    +deviceWidth(1200,0)
      max-width: 1440px
      padding: 0 80px
  &.theme1
    background-color: #111111
    header
      background-color: #111111
    .dropdown
      +deviceWidth()
        background-color: #111111
  &.theme2
    background-color: #f9f9f9
    header
      background-color: #f9f9f9
    .dropdown
      +deviceWidth()
        background-color: #f9f9f9
  &.theme3
    background-color: #fcfcfc
    header
      background-color: #fcfcfc
    .dropdown
      +deviceWidth()
        background-color: #fcfcfc
  .ss-row
    margin-right: -10px
    margin-left: -10px
  .col-6, .col-12, .col-md-3
    padding-right: 10px
    padding-left: 10px
  .ss-col-2
    +deviceWidth(1200,0)
      flex: 0 0 20%
      max-width: 20%
      padding-right: 10px
      padding-left: 10px

.main
  +deviceWidth(1200,0)
    min-height: calc(100vh - 104px - 166px)
  +deviceWidth(768,1199)
    min-height: calc(100vh - 64px - 166px)

.fade-enter-active, .fade-leave-active
  transition: opacity .5s

.fade-enter, .fade-leave-to
  opacity: 0

div.hr
  width: 101.25%
  height: 1px
  background-color: #c5c5c5
  margin-top: 130px
  margin-bottom: 92px
  position: relative
  left: -0.625%
  opacity: .4
  +deviceWidth()
    margin-top: 100px
    margin-bottom: 60px
    opacity: 1
    width: 100%
    left: 0
</style>
