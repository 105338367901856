<template>
  <div class="contact-page main">
    <div class="ss-container">
      <div class="d-flex flex-column flex-md-row align-items-center">
        <div class="img">
          <img
            :src="require('../assets/contact.jpeg')"
            alt="Chihyi CC 林芷伊"
          />
        </div>
        <div class="contact">
          <a href="mailto:ccqqraina@gmail.com">mail : ccqqraina@gmail.com</a>
          <div class="btns">
            <a href="https://www.instagram.com/chihyicc/" target="_blank">
              Instagram
            </a>
            <a href="https://www.behance.net/chihyi_CC" target="_blank">
              Behance
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {}
};
</script>

<style lang="sass" scoped>
.contact-page
  padding-top: 130px
  padding-bottom: 150px
  +deviceWidth()
    padding-top: 50px
    padding-bottom: 80px
.img
  width: 100%
  border-radius: 20px
  overflow: hidden
  position: relative
  +deviceWidth(768,0)
    max-width: 255px
    left: -6px
  img
    max-width: 100%
    height: auto
.contact
  display: flex
  flex-direction: column
  +deviceWidth(0,767)
    align-items: center
    margin-top: 30px
  +deviceWidth(768,0)
    margin-left: 62px
  a
    color: rgba(0, 0, 0, 0.9)
    font-family: 'Avenir', sans-serif
    font-size: 18px
    letter-spacing: .71px
    display: block
    +deviceWidth()
      font-size: 20px
    &:hover
      text-decoration: none
      color: rgba(0, 0, 0, 0.9)
    &:focus
      outline: none
  .btns
    display: flex
    align-items: center
    margin-top: 40px
    +deviceWidth()
      margin-top: 36px
    a
      display: block
      padding-bottom: 3px
      color: #a2a2a2
      font-size: 14px
      letter-spacing: .5px
      font-family: 'Avenir', sans-serif
      position: relative
      &::after
        content: ''
        position: absolute
        width: 101%
        height: 2px
        border-radius: 1px
        background-color: #c4c4c4
        left: 0
        top: 100%
      &:first-child
        margin-right: 25px
      &:hover
        text-decoration: none
      &:focus
        outline: none
</style>
