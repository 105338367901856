<template>
  <footer>
    <div class="ss-container">
      <div class="d-flex flex-column flex-md-row">
        <div class="d-flex flex-column">
          <h6>follow</h6>
          <a href="https://www.instagram.com/chihyicc/" target="_blank"
            >Instagram</a
          >
          <a href="https://www.behance.net/chihyi_CC" target="_blank"
            >Behance</a
          >
        </div>
        <div class="d-flex flex-column">
          <h6>contact</h6>
          <a href="mailto:ccqqraina@gmail.com">ccqqraina@gmail.com</a>
        </div>
      </div>

      <p>
        Copyright © www.chihyicc.com All Rights Reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped lang="sass">
footer
  background-color: #ffffff
  padding: 48px 0 26px
  position: relative
  +deviceWidth(768,0)
    padding: 33px 0 25px 5px
  div
    &.ss-container
      display: flex
      flex-direction: column
    &:nth-child(2)
      margin-top: 48px
      +deviceWidth(768,0)
        margin-left: 100px
        margin-top: 0
  h6
    color: #111111
    opacity: .95
    letter-spacing: .5px
    padding-bottom: 20px
    margin-bottom: 0
    font:
      family: Avenir, sans-serif
      size: 14px
      weight: 900
  a
    color: #111111
    opacity: .95
    letter-spacing: .5px
    font-family: Avenir, sans-serif
    font-size: 14px
    display: block
    +deviceWidth()
      line-height: 1.15
    &:nth-child(2)
      padding-bottom: 4px
      +deviceWidth()
        padding-bottom: 12px
    &:focus
      outline: none
  p
    color: #111111
    opacity: .3
    letter-spacing: .5px
    font-family: Avenir, sans-serif
    font-size: 12px
    margin-bottom: 0
    text-align: left
    line-height: 1.75
    +deviceWidth()
      padding-top: 56px
    +deviceWidth(768,0)
      text-align: right
</style>
