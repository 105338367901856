<template>
  <div class="home main">
    <section id="illustration-section">
      <div class="ss-container">
        <h6 class="section-title">Illustration</h6>
        <p class="section-desc">一些小插畫</p>
      </div>

      <ListTemplate
        :data="fixedIllustrations"
        category="illustration"
      ></ListTemplate>

      <div class="more-btn-wrapper">
        <div class="ss-container">
          <router-link to="/illustration" class="more-btn text-white">
            more
            <span>
              <img :src="require('../assets/icon_more_wh.svg')" alt="箭頭" />
            </span>
          </router-link>
        </div>
      </div>
    </section>

    <section id="story-section">
      <div class="ss-container">
        <h6 class="section-title">Story</h6>
        <p class="section-desc">那些小故事</p>
      </div>

      <ListTemplate :data="fixedStories" category="story"></ListTemplate>

      <div class="more-btn-wrapper">
        <div class="ss-container ">
          <router-link to="/stories" class="more-btn text-white">
            more
            <span>
              <img :src="require('../assets/icon_more_wh.svg')" alt="箭頭" />
            </span>
          </router-link>
        </div>
      </div>
    </section>

    <section id="character-section">
      <div class="ss-container">
        <h6 class="section-title">Character</h6>
        <p class="section-desc">這些小角色</p>
      </div>

      <ListTemplate :data="fixedCharacters" category="character"></ListTemplate>

      <div class="more-btn-wrapper">
        <div class="ss-container ">
          <router-link to="/characters" class="more-btn text-white">
            more
            <span>
              <img :src="require('../assets/icon_more_wh.svg')" alt="箭頭" />
            </span>
          </router-link>
        </div>
      </div>
    </section>

    <section id="portrait-section">
      <div class="ss-container">
        <h6 class="section-title">Caricature Portrait</h6>
        <p class="section-desc">客訂似顏繪</p>
      </div>

      <ListTemplate :data="fixedPortraits" category="portrait"></ListTemplate>

      <div class="more-btn-wrapper">
        <div class="ss-container">
          <router-link to="/portraits" class="more-btn text-white">
            more
            <span>
              <img :src="require('../assets/icon_more_wh.svg')" alt="箭頭" />
            </span>
          </router-link>
        </div>
      </div>
    </section>

    <section id="commercial-illustration-section">
      <div class="ss-container">
        <h6 class="section-title text-black">Commercial Illustration</h6>
        <p class="section-desc text-black">商業插畫設計</p>
      </div>

      <ListTemplate
        :data="fixedCommercialIllustrations"
        category="commercial-illustration"
      ></ListTemplate>

      <div class="more-btn-wrapper">
        <div class="ss-container">
          <router-link
            to="/commercial-illustration"
            class="more-btn text-black"
          >
            more
            <span>
              <img :src="require('../assets/icon_more_bk.svg')" alt="箭頭" />
            </span>
          </router-link>
        </div>
      </div>
    </section>

    <section id="book-cover-design-section">
      <div class="ss-container">
        <h6 class="section-title text-black">Book Cover Design</h6>
        <p class="section-desc text-black">書籍封面設計</p>
      </div>

      <ListTemplate
        :data="fixedBookCoverDesign"
        category="book-cover-design"
      ></ListTemplate>

      <div class="more-btn-wrapper">
        <div class="ss-container ">
          <router-link to="/book-cover-design" class="more-btn text-black">
            more
            <span>
              <img :src="require('../assets/icon_more_bk.svg')" alt="箭頭" />
            </span>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ListTemplate from "@/components/ListTemplate.vue";
import Illustrations from "@/data/illustrations.js";
import Characters from "@/data/characters.js";
import Stories from "@/data/stories.js";
import Portraits from "@/data/portraits.js";
import CommercialIllustrations from "@/data/commercialIllustrations.js";
import BookCoverDesign from "@/data/bookCoverDesign.js";
export default {
  name: "Home",
  components: {
    ListTemplate
  },
  data() {
    return {
      illustrations: Illustrations,
      characters: Characters,
      stories: Stories,
      portraits: Portraits,
      commercialIllustrations: CommercialIllustrations,
      bookCoverDesign: BookCoverDesign
    };
  },
  computed: {
    fixedIllustrations() {
      return this.illustrations.filter(function(item) {
        return item.fixed && item.fixed === 1;
      });
    },
    fixedCharacters() {
      return this.characters.filter(function(item) {
        return item.fixed && item.fixed === 1;
      });
    },
    fixedStories() {
      return this.stories.filter(function(item) {
        return item.fixed && item.fixed === 1;
      });
    },
    fixedPortraits() {
      return this.portraits.filter(function(item) {
        return item.fixed && item.fixed === 1;
      });
    },
    fixedCommercialIllustrations() {
      return this.commercialIllustrations.filter(function(item) {
        return item.fixed && item.fixed === 1;
      });
    },
    fixedBookCoverDesign() {
      return this.bookCoverDesign.filter(function(item) {
        return item.fixed && item.fixed === 1;
      });
    }
  }
};
</script>

<style lang="sass">
img
  max-width: 100%
  height: auto
.home
  .more-btn-wrapper
    width: 100%
    +deviceWidth(768,0)
      position: absolute
      bottom: 73px
    .ss-container
      display: flex
      justify-content: flex-end
      align-items: center
  .more-btn
    font-size: 14px
    font-family: Avenir, sans-serif
    opacity: .9
    display: inline-block
    &:focus
      outline: none
    &:hover
      text-decoration: none
    &.text-black
      color: #111111
    &.text-white
      color: #fafafa
#illustration-section
  padding-top: 132px
  background-color: #111111
  padding-bottom: 28px
  position: relative
  +deviceWidth()
    padding-top: 50px
#story-section
  padding-top: 20px
  background-color: #111111
  padding-bottom: 28px
  position: relative
#character-section
  padding-top: 20px
  background-color: #111111
  padding-bottom: 28px
  position: relative
#portrait-section
  padding-top: 20px
  background-color: #111111
  padding-bottom: 54px
  position: relative
#commercial-illustration-section
  padding-top: 100px
  background-color: #f9f9f9
  padding-bottom: 28px
  position: relative
  +deviceWidth()
    padding-top: 50px
#book-cover-design-section
  padding-top: 20px
  background-color: #f9f9f9
  padding-bottom: 28px
  position: relative

.section-title
  color: #fafafa
  font-size: 14px
  letter-spacing: .44px
  font-family: ' Avenir', sans-serif
  opacity: .9
  display: inline-block
  margin-right: 8px
  margin-bottom: 24px
  font-weight: 500
  +deviceWidth()
    margin-bottom: 12px
  &.text-black
    color: #111111
.section-desc
  color: #fafafa
  font-size: 14px
  letter-spacing: 1px
  font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
  opacity: .9
  margin-bottom: 0
  display: inline-block
  font-weight: 500
  &.text-black
    color: #111111
.br-18
  border-radius: 18px
.overflow-hidden
  overflow: hidden
.bg-black
  background-color: #111111
.bg-white
  background-color: #f9f9f9
</style>
