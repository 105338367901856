<template>
  <div class="error-page main">
    <h4>找不到網頁</h4>
    <p>
      很抱歉，您要找的網頁不存在或已被移除。
    </p>
  </div>
</template>

<script>
export default {
  name: "Error404"
};
</script>

<style lang="sass" scoped>
.error-page
  padding-top: 50px
h4
  font-size: 20px
  color: #fafafa
  text-align: center
p
  font-size: 16px
  color: #fafafa
  text-align: center
</style>
