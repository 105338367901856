// size 1: 圖方的，小的 2: 圖長的，滿版
var characters = [
  {
    id: 1,
    list_img: require("../assets/character/list_Character_fluid_01.jpeg"),
    orig_img: [
      require("../assets/character/item_Character_fluid_01a.jpeg"),
      require("../assets/character/item_Character_fluid_01b.jpeg"),
      require("../assets/character/item_Character_fluid_01c.jpeg")
    ],
    title: "那些奇怪生物們",
    description: "",
    size: 2,
    fixed: 0
  },
  {
    id: 2,
    list_img: require("../assets/character/list_Character_sq_01.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_01.jpeg")],
    title: "見習咖啡師",
    description: "拉出完美的拉花。",
    size: 1,
    fixed: 1
  },
  {
    id: 3,
    list_img: require("../assets/character/list_Character_sq_02.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_02.jpeg")],
    title: "節分祭",
    description: "日本節分祭就是要吃惠方卷、灑豆子驅鬼。",
    size: 1,
    fixed: 1
  },
  {
    id: 4,
    list_img: require("../assets/character/list_Character_sq_03.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_03.jpeg")],
    title: "帥氣滑板",
    description: "技術0分，姿勢100。",
    size: 1,
    fixed: 1
  },
  {
    id: 5,
    list_img: require("../assets/character/list_Character_sq_04.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_04.jpeg")],
    title: "許願貓",
    description: "你有什麼願望嗎？",
    size: 1,
    fixed: 1
  },
  {
    id: 6,
    list_img: require("../assets/character/list_Character_sq_05.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_05.jpeg")],
    title: "冬至堆湯圓大師",
    description: "湯圓雪人沒看過吧？",
    size: 1,
    fixed: 1
  },
  {
    id: 7,
    list_img: require("../assets/character/list_Character_sq_06.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_06.jpeg")],
    title: "悠閒的下午",
    description: "悠悠哉哉",
    size: 1,
    fixed: 1
  },
  {
    id: 8,
    list_img: require("../assets/character/list_Character_sq_07.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_07.jpeg")],
    title: "乳酪大盜",
    description: "不准動！",
    size: 1,
    fixed: 1
  },
  {
    id: 9,
    list_img: require("../assets/character/list_Character_sq_08.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_08.jpeg")],
    title: "盤子上的野餐",
    description: "野餐爭奪戰",
    size: 1,
    fixed: 1
  },
  {
    id: 10,
    list_img: require("../assets/character/list_Character_sq_09.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_09.jpeg")],
    title: "會飛向太空的魚1",
    description: "抓好就能上月球",
    size: 1,
    fixed: 1
  },
  {
    id: 11,
    list_img: require("../assets/character/list_Character_sq_10.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_10.jpeg")],
    title: "會飛向太空的魚2",
    description: "準備起飛！",
    size: 1,
    fixed: 1
  },
  {
    id: 12,
    list_img: require("../assets/character/list_Character_fluid_02.jpeg"),
    orig_img: [
      require("../assets/character/item_Character_fluid_02a.jpeg"),
      require("../assets/character/item_Character_fluid_02b.jpeg"),
      require("../assets/character/item_Character_fluid_02c.jpeg")
    ],
    title: "早餐吃什麼？",
    description: "",
    size: 2,
    fixed: 0
  },
  {
    id: 13,
    list_img: require("../assets/character/list_Character_sq_11.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_11.jpeg")],
    title: "會飛向太空的魚3",
    description: "把魚鱗抹在披風上也是可以的。",
    size: 1,
    fixed: 0
  },
  {
    id: 14,
    list_img: require("../assets/character/list_Character_sq_12.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_12.jpeg")],
    title: "喵喵外星人",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 15,
    list_img: require("../assets/character/list_Character_sq_13.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_13.jpeg")],
    title: "紳士貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 16,
    list_img: require("../assets/character/list_Character_sq_14.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_14.jpeg")],
    title: "猴子小隊",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 17,
    list_img: require("../assets/character/list_Character_sq_15.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_15.jpeg")],
    title: "時尚帽子",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 18,
    list_img: require("../assets/character/list_Character_sq_16.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_16.jpeg")],
    title: "溫泉貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 19,
    list_img: require("../assets/character/list_Character_sq_17.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_17.jpeg")],
    title: "害羞狗",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 20,
    list_img: require("../assets/character/list_Character_sq_18.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_18.jpeg")],
    title: "花瓶貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 21,
    list_img: require("../assets/character/list_Character_sq_19.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_19.jpeg")],
    title: "雪人貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 22,
    list_img: require("../assets/character/list_Character_sq_20.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_20.jpeg")],
    title: "疊疊雞樂",
    description: "等等準備出菜啦～",
    size: 1,
    fixed: 0
  },
  {
    id: 23,
    list_img: require("../assets/character/list_Character_fluid_03.jpeg"),
    orig_img: [require("../assets/character/item_Character_fluid_03a.gif")],
    title: "",
    description: "",
    size: 2,
    fixed: 0
  },
  {
    id: 24,
    list_img: require("../assets/character/list_Character_sq_21.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_21.jpeg")],
    title: "特技貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 25,
    list_img: require("../assets/character/list_Character_sq_22.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_22.jpeg")],
    title: "丸子貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 26,
    list_img: require("../assets/character/list_Character_sq_23.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_23.jpeg")],
    title: "塞車貓",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 27,
    list_img: require("../assets/character/list_Character_sq_24.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_24.jpeg")],
    title: "丸子三兄弟",
    description: "",
    size: 1,
    fixed: 0
  },
  {
    id: 28,
    list_img: require("../assets/character/list_Character_sq_25.jpeg"),
    orig_img: [require("../assets/character/list_Character_sq_25.jpeg")],
    title: "飯桶喵",
    description: "",
    size: 1,
    fixed: 0
  }
];

export default characters;
