<template>
  <header :class="burgerMenuOpen ? 'active' : ''">
    <div class="ss-container">
      <router-link to="/" class="logo">
        <h5 :class="theme == 1 ? 'text-white' : 'text-black'">
          CHIHYI
        </h5>
      </router-link>

      <nav class="menubar">
        <ul class="menubar-ul">
          <li class="z-index-15">
            <button
              type="button"
              :class="theme == 1 ? 'text-white' : 'text-black'"
              @click="toggleDropdownMenu()"
            >
              Projects
            </button>
            <div
              class="dropdown"
              :class="[
                dropdownMenuOpen ? 'active' : '',
                theme == 1 ? 'text-white' : 'text-black'
              ]"
            >
              <ul>
                <li>
                  <router-link to="/illustration"
                    >Illustration <span>一些小插畫</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/stories">
                    Story <span>那些小故事</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/characters"
                    >Character <span>這些小角色</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/portraits"
                    >Caricature Portrait <span>客訂似顏繪</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/commercial-illustration"
                    >Commercial Illustration <span>商業插畫設計</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/book-cover-design"
                    >Book Cover Design <span>書籍封面設計</span>
                  </router-link>
                </li>
              </ul>
              <button type="button" class="back" @click="closeDropdownMenu()">
                <img
                  :src="
                    theme == 1
                      ? require('../assets/icon_back_wh.svg')
                      : require('../assets/icon_back_bk.svg')
                  "
                  alt="箭頭"
                />
              </button>
              <button
                class="hamburger hamburger--slider is-active"
                type="button"
                @click="closeBothMenus()"
              >
                <span class="hamburger-box">
                  <span
                    class="hamburger-inner"
                    :class="theme == 1 ? 'bg-white' : 'bg-black'"
                  ></span>
                </span>
              </button>
            </div>
          </li>
          <li class="">
            <router-link
              to="/about"
              :class="theme == 1 ? 'text-white' : 'text-black'"
              >About</router-link
            >
          </li>
          <li class="">
            <router-link
              to="/contact"
              :class="theme == 1 ? 'text-white' : 'text-black'"
              >Contact</router-link
            >
          </li>
          <li class="d-md-none">
            <div class="hr"></div>
          </li>
          <li class="instagram">
            <a href="https://www.instagram.com/chihyicc/" target="_blank">
              <img
                :src="
                  theme == 1
                    ? require('../assets/ig_logo_wh.svg')
                    : require('../assets/ig_logo_bk.svg')
                "
                alt="instagram"
              />
            </a>
          </li>
        </ul>
      </nav>

      <button
        class="hamburger hamburger--slider"
        :class="burgerMenuOpen ? 'is-active' : ''"
        type="button"
        @click="toggleBurgerMenu()"
      >
        <span class="hamburger-box">
          <span
            class="hamburger-inner"
            :class="theme == 1 ? 'bg-white' : 'bg-black'"
          ></span>
        </span>
      </button>
    </div>
  </header>
</template>

<script>
import "hamburgers/dist/hamburgers.css";
export default {
  name: "Header",
  props: {
    theme: Number
  },
  data() {
    return {
      burgerMenuOpen: false,
      dropdownMenuOpen: false
    };
  },
  watch: {
    $route: function(to, from) {
      if (to.path !== from.path) {
        this.burgerMenuOpen = false;
        this.dropdownMenuOpen = false;
        window.scrollTo(0, 0);
      }
    }
  },
  methods: {
    toggleBurgerMenu() {
      this.burgerMenuOpen = !this.burgerMenuOpen;
    },
    toggleDropdownMenu() {
      this.dropdownMenuOpen = !this.dropdownMenuOpen;
    },
    openDropdownMenu() {
      this.dropdownMenuOpen = true;
    },
    closeDropdownMenu() {
      this.dropdownMenuOpen = false;
    },
    closeBothMenus() {
      this.burgerMenuOpen = false;
      this.dropdownMenuOpen = false;
    }
  }
};
</script>

<style scoped lang="sass">
header
  transition: .5s
  width: 100%
  padding: 40px 0
  +deviceWidth()
    position: fixed
    top: 0
    left: 0
    height: 64px
    overflow: hidden
    padding: 20px
    z-index: 10
  +deviceWidth(768,1199)
    padding: 20px 0
  .ss-container
    +deviceWidth()
      height: 100%
      padding: 0
    .menubar
      +deviceWidth()
        height: 100%
        max-height: 0
        display: flex
        align-items: flex-end
        justify-content: center
      .menubar-ul
        +deviceWidth()
          flex-direction: column
          max-height: 0
          width: 100%
          padding: 0 17.5px 24%
        li
          +deviceWidth()
            margin-bottom: 26.6%
            width: 100%
            display: flex
            justify-content: center
            align-items: center
          &:last-child
            +deviceWidth()
              margin-bottom: 0
              max-height: 0
            img
              +deviceWidth()
                max-height: 0
              +deviceWidth(768,0)
                padding: 40px 0
  &.active
    +deviceWidth()
      height: 100vh
      z-index: 10
    .ss-container
      +deviceWidth()
        padding: 0 !important
      a.logo
        display: none
      .menubar
        max-height: 100%
        .menubar-ul
          max-height: 100%
          li
            &:last-child
              max-height: 100%
              img
                max-height: 100%
  .ss-container
    position: relative
  a.logo
    display: inline-block
    position: absolute
    top: 0
    left: 0
    z-index: 10
    +deviceWidth(768,1199)
      left: 20px
    +deviceWidth(1200,0)
      left: 80px
    h5
      font-family: Avenir, sans-serif
      font-size: 18px
      letter-spacing: 1px
      opacity: .95
      margin-bottom: 0
      &.text-white
        color: #fafafa
      &.text-black
        color: #111111
    &:focus
      outline: none
    &:hover
      text-decoration: none
  .menubar
    position: relative
    +deviceWidth(1200,0)
      left: 16px
    ul.menubar-ul
      list-style: none
      display: flex
      justify-content: flex-end
      align-items: center
      margin-bottom: 0
      padding: 0
      li
        display: flex
        align-items: center
        position: relative
        +deviceWidth(768,0)
          margin-right: 68px
        &:nth-child(3)
          +deviceWidth(768,0)
            margin-right: 40px
        &:last-child.instagram
          +deviceWidth(768,0)
            margin-right: 0
          a
            width: 18px
            height: 18px
            display: flex
            align-items: center
            +deviceWidth()
              width: 30px
              height: 30px
            img
              max-width: 100%
              height: auto
        a,button
          &:hover
            text-decoration: none
          &.text-white
            color: #fafafa
          &.text-black
            color: #111111
          &:focus
            outline: none
          display: block
          font-family: Avenir, sans-serif
          font-size: 16px
          letter-spacing: .5px
          opacity: .95
          background-color: transparent
          border: none
          +deviceWidth()
            opacity: 1
            font-size: 24px
            letter-spacing: .24px
        .dropdown
          background-color: #fff
          position: absolute
          top: 140%
          border-radius: 18px
          width: 354px
          height: 264px
          max-height: 0
          overflow: hidden
          &.active
            max-height: 264px
            padding: 24px 28px
            box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1)
            z-index: 5
            +deviceWidth()
              width: 100vw
              height: 100vh
              position: fixed
              top: 0
              left: 0
              border-radius: 0
              max-height: 100%
              padding: calc(36.8% - 7.16%) 20px 0
            ul
              +deviceWidth()
                padding-bottom: 24%
                max-height: 100%
                overflow: auto
              li
                a
                  +deviceWidth()
                    padding: 7.16% 0
            .hamburger
              +deviceWidth()
                position: fixed
                top: 20px
                right: 20px
                z-index: 10
                padding-top: 2px
              +deviceWidth(768,0)
                display: none
            .back
              +deviceWidth()
                position: fixed
                top: 23px
                left: 20px
                z-index: 10
                width: 26px
                padding: 0
              +deviceWidth(768,0)
                display: none
              &:focus
                outline: none
              img
                max-width: 100%
                height: auto
                display: block
          &.text-white
            ul
              li
                a
                  +deviceWidth()
                    color: #fafafa
          &.text-black
            ul
              li
                a
                  +deviceWidth()
                    color: #111111
          ul
            list-style: none
            padding: 0
            margin: 0
            li
              margin: 0
              a
                &:hover
                  opacity: .5
                display: block
                font-family: Avenir, sans-serif
                font-size: 14px
                letter-spacing: .44px
                padding: 8px 0
                width: 100%
                transition: opacity .3s
                color: rgba(17, 17, 17, 0.9)
                line-height: 1.45
                font-weight: 500
                +deviceWidth()
                  font-size: 16px
                  line-height: 1
                span
                  letter-spacing: 1px
                  font-family: 'PingFang TC', 'Microsoft JhengHei', sans-serif
                  +deviceWidth()
                    display: block
                    padding-top: 2.4%
.hamburger
  padding: 2px 0 0 0
  display: flex
  align-items: center
  position: absolute
  right: 0
  top: 0
  +deviceWidth(768,0)
    display: none
  &:focus
    outline: none
  &.is-active
    opacity: 1
    &:hover
      opacity: 1
  .hamburger-box
    width: 24px
    .hamburger-inner
      width: 24px
      height: 1px
      &::before, &::after
        width: 24px
        height: 1px
      &.bg-white
        background-color: #fafafa
        &::before, &::after
          background-color: #fafafa
      &.bg-black
        background-color: #111111
        &::before, &::after
          background-color: #111111

div.hr
  width: 100%
  height: 1px
  background-color: #c5c5c5
  margin: 0
  left: 0
  opacity: 1
  margin-top: 7px
  margin-bottom: 15px
</style>
